(function(Pag) {
    'use strict';

    var toggle = document.querySelector('.js-overview-toggle');
    var overview = document.querySelector('.js-overview');

    var countryToggle = document.querySelector('.js-country-toggle');
    var countryOverview = document.querySelector('.js-country');

    var overlay = document.querySelector('.js-overview-overlay');

    Pag.showOverview = function (event) {
        event.preventDefault();
        Pag.hideOverlays();

        toggle.classList.add('is-active');
        overview.classList.remove('is-hidden');

        overlay.classList.remove('is-hidden');
    };

    Pag.showCountryOverview = function(event){
        event.preventDefault();
        Pag.hideOverlays();

        countryToggle.classList.add('is-active');
        countryOverview.classList.remove('is-hidden');

        overlay.classList.remove('is-hidden');
    };

    Pag.hideOverlays = function(event){
        if (event) {
            event.preventDefault();
        }

        if (toggle != null) {
            toggle.classList.remove('is-active');
            overview.classList.add('is-hidden');
        }

        if (countryToggle != null) {
            countryToggle.classList.remove('is-active');
            countryOverview.classList.add('is-hidden');
        }

        overlay.classList.add('is-hidden');
    };

    Pag.onDomReady = function () {
        Pag.Cookiebar.init();

        if (toggle != null) {
            toggle.addEventListener('click', Pag.showOverview);
        }

        if (countryToggle != null) {
            countryToggle.addEventListener('click', Pag.showCountryOverview);
        }

        if (overlay != null) {
            overlay.addEventListener('click', Pag.hideOverlays);
        }
    };

})(window.Pag = window.Pag || {});

document.addEventListener('DOMContentLoaded', Pag.onDomReady);
