(function(Cookiebar) {
    'use strict';

    Cookiebar.cookiebar;
    Cookiebar.trigger;
    Cookiebar.accepted = false;
    Cookiebar.options = {
        'selectorCookiebar': '.js-cookiebar',
        'selectorCookiebarAccept': '.js-cookiebar-accept',
        'name': 'w2wCookie',
        'value': 'accepted',
        'expireDays': 365,
        'path': '/'
    };

    Cookiebar.init = function() {
        Cookiebar.cookiebar = document.querySelector(Cookiebar.options.selectorCookiebar);
        Cookiebar.trigger = document.querySelector(Cookiebar.options.selectorCookiebarAccept);

        if (typeof Cookies === "undefined") {
            console.error('[Cookiebar] js-cookie dependency not defined, please provide the js-cookie library.');
            return;
        }

        if (!Cookiebar.cookiebar) {
            console.error('[Cookiebar] cookiebar not found, please provide markup.');
            return;
        }

        if (Cookies.get(Cookiebar.options.name) !== undefined) {
            Cookiebar.accept();
        } else {
            Cookiebar.trigger.addEventListener('click', Cookiebar.accept, true);
            Cookiebar.cookiebar.removeAttribute('hidden');
        }

    };

    Cookiebar.accept = function(setCookie) {
        Cookiebar.accepted = true;

        if (setCookie) {
            Cookies.set(Cookiebar.options.name, Cookiebar.options.value, { expires: Cookiebar.options.expireDays, path: Cookiebar.options.path });
        }

        Cookiebar.hideCookiebar();
    };

    Cookiebar.hideCookiebar = function() {
        Cookiebar.cookiebar.setAttribute('hidden', true);
    };

})(window.Pag.Cookiebar = window.Pag.Cookiebar || {});


